<template>
    <div class="main">
        <div class="banner">
            <div class="banner-overlay"></div>
             <h1>NOTRE SOLUTION,<br /> SES FONCTIONNALITES & MODULES</h1>
        </div>
        <section class="title-section">
            <div class="row">
                <h2 class="gradiant-text">Modulez notre solution selon vos besoins spéciﬁques<br /> et répondez aux attentes de vos exposants !</h2>
            </div>
        </section>
        <section class="solution-1">
            <div class="sep sep-top"></div>
            <div class="sep sep-bottom"></div>
            <div class="row">
                <h2 class="gradiant-text">Pack standard<br /> "My-Virtual-Convention"</h2>
            </div>
            <div class="row">
                <p class="text-header">Notre offre standard intègre l'installation et la mise en place de votre solution de salon virtuel avec l’ensemble des modules génériques suivants :</p>
            </div>
            <div class="accordion-container">
                <!-- Accordion 1 -->
                <div class="accordion-item accordion1">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Hall d'exposition & Stands virtuels Exposants</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Les stands des exposants sont situés dans un hall d’exposition virtuel et leur design est adapté à la charte graphique de chacune des entités qui exposent. Ils permettent d'informer les visiteurs sur une société, ses produits et services et proposent plusieurs fonctionnalités :</p>
                        <div class="row">
                            <div class="col33">
                                <ul>
                                    <li>Stand Virtuel Interactif :
                                        <ul>
                                            <li>Photo simple fournie par l’exposant</li>
                                            <li>Rendu 3D interactif générique</li>
                                            <li>Sous forme d’un ou plusieurs visuels 3D interactifs</li>
                                            <li>Sous forme d’un ou plusieurs 360 interactifs</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="col33">
                                <ul>
                                    <li>Fiche d’identité détaillée</li>
                                    <li>Produits & Services</li>
                                    <li>Documentation commerciale</li>
                                    <li>Documentation spécialisée</li>
                                    <li>Vidéos</li>
                                </ul>
                            </div>
                            <div class="col33">
                                <ul>
                                    <li>Workshops & Démos produits</li>
                                    <li>Offres d’emploi</li>
                                    <li>Formulaire de contact</li>
                                    <li>Lien externe vers Site Web</li>
                                    <li>Tchat & Rencontres visio permettant aux visiteurs et au personnel des stands d’échanger en direct </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Accordion 2 -->
                <div class="accordion-item accordion2">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Visiteurs</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Inscription ou import .CSV des visiteurs et mise en place de leur compte utilisateur.<br /> L'inscription peut être gratuite, payante, avec validation manuelle ou automatique selon vos besoins.</p>
                    </div>
                </div>
                
                <!-- Accordion 3 -->
                <div class="accordion-item accordion3">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Echanges & Communication</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Interaction entre les utilisateurs : envoi d'email, tchat en direct, visio (Skype ou via un module interne open-source), etc.</p>
                    </div>
                </div>

                <!-- Accordion 4 -->
                <div class="accordion-item accordion4">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Sponsors</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Bannière de mise en avant des sponsors de l'événement.</p>
                    </div>
                </div>

                <!-- Accordion 5 -->
                <div class="accordion-item accordion5">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Statistiques & KPI</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Aussi bien pour l'organisateur que pour les exposants, récupération de statistiques de fréquentation très détaillées : nombre de visiteurs, nombre de visiteurs uniques, produits/services les plus consultés, fichiers media les plus visionnés, etc.
                            En effet, le grand avantage d’un salon virtuel est l’acquisition complète des données de chaque visiteur d'un stand.<br /><br />
                            Via la page d'inscription, les visiteurs remplissent, outre leurs informations basiques, des informations plus avancées (comme par exemple : société, fonction, numéros de téléphone, centres d'intérêts, langues parlées, spécialités, etc.)<br />
                            Toutes les actions effectuées par l'utilisateur sur le salon digital sont enregistrées de manière individuelle : stands visités, documents et vidéos regardés et téléchargés, produits et services consultés, etc.<br /><br />
                            Ces données sont alors disponibles pour l'exposant afin de fournir à ses commerciaux et à son service Administration Des Ventes (ADV) des leads qualifiés enrichis de données comportementales extrêmement précises.
                        </p>
                    </div>
                </div>

                <!-- Accordion 6 -->
                <div class="accordion-item accordion6">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Outils de recherche</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Recherche parmi les exposants et les participants grâce à des filtres de recherche avancés.</p>
                    </div>
                </div>

                <!-- Accordion 7 -->
                <div class="accordion-item accordion7">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Favoris</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Mise en favoris des exposants, des conférences ou des autres participants afin de pouvoir les retrouver ultérieurement plus facilement.</p>
                    </div>
                </div>

                <!-- Accordion 8 -->
                <div class="accordion-item accordion8">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Calendrier</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Mise en calendrier des conférences ou des RDV convenus avec d'autres participants.</p>
                    </div>
                </div>

                <!-- Accordion 9 -->
                <div class="accordion-item accordion9">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Multilingue</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>L'interface graphique de notre plateforme est associée à une langue par défaut définie lors de l'installation initiale. Toutefois, chaque utilisateur pourra afficher la plateforme dans une autre langue.</p>
                    </div>
                </div>

                <!-- Accordion 10 -->
                <div class="accordion-item accordion10">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Admin Organisateur</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Back-Office d'administration permettant à l'organisateur de gérer ses visiteurs, son programme de conférences, ses sponsors ainsi que de consulter ses statistiques de fréquentation.</p>
                    </div>
                </div>

                <!-- Accordion 11 -->
                <div class="accordion-item accordion11">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Admin Exposant</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Back-Office d'administration permettant à chaque exposant de gérer le contenu de son stand virtuel ainsi que de consulter ses statistiques de fréquentation.</p>
                    </div>
                </div>

            </div>
        </section>
        <section class="solution-2">
            <div class="row">
                <h2 class="gradiant-text">Modules<br /> optionnels complémentaires</h2>
            </div>
            <div class="accordion-container">
                <!-- Accordion 12 -->
                <div class="accordion-item accordion12">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Vidéo 3D immersive d'introduction </h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Conception & Production d'une animation 3D d'introduction pour aussitôt plonger vos visiteurs dans l'univers de votre événement digital.</p>
                    </div>
                </div>

                <!-- Accordion 13 -->
                <div class="accordion-item accordion13">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Parvis 3D animé sur mesure</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Conception & Production d'une animation 3D d'introduction pour aussitôt plonger vos visiteurs dans l'univers de votre événement digital.</p>
                    </div>
                </div>

                <!-- Accordion 14 -->
                <div class="accordion-item accordion14">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Lobby / Hall d'accueil</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Après avoir saisi leurs identifiants de connexion, les visiteurs entrent dans l’évènement virtuel et arrivent au sein d'un hall d’entrée digital. <br /><br />
                            Le lobby est le point de navigation central pour explorer un événement digital. Depuis celui-ci, les visiteurs sélectionnent l'espace qu'ils veulent découvrir et visiter. 
                        </p>
                    </div>
                </div>

                <!-- Accordion 15 -->
                <div class="accordion-item accordion15">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Auditorium virtuel de Conférences</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            L’auditorium est un espace virtuel pour les conférences, les symposiums, les tables rondes et les discussions.<br />
                            Les conférences peuvent être :
                            <ul>
                                <li>Live (via toutes solutions qui génèrent une URL de type Zoom, Teams, GoToMeeting, Meet, etc.)</li>
                                <li>Diffusées en direct depuis un studio ou une salle de conférence : streaming en temps réel. Ceci est particulièrement recommandé pour les événements hybrides afin de transmettre aux visiteurs du salon la sensation d’un évènement réel</li>
                                <li>Préenregistrée visible de manière permanente</li>
                                <li>Préenregistrée visible qu’à partir d’une date/heure donnée</li>
                            </ul>
                        </p>
                    </div>
                </div>

                <!-- Accordion 16 -->
                <div class="accordion-item accordion16">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Auditorium de conférences techniques & workshops proposés par les exposants</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>Promotion de l'ensemble des conférences techniques et workshops proposés par les exposants au sein d'un même programme.</p>
                    </div>
                </div>

                <!-- Accordion 17 -->
                <div class="accordion-item accordion17">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Networking</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Lors des événements en physique, il est toujours frustrant de ne pas savoir qui visitent le salon en même temps que vous !<br />
                            L'espace de networking permet, grâce à plusieurs filtres de recherche précis, de "scanner" la base de données des autres visiteurs pour entrer en relation avec eux (selon les droits et paramètres qu'ils ont eux-mêmes données et sélectionnés). Chaque visiteur peut donc avoir l'opportunité d'interagir et de "faire du business" avec les autres utilisateurs.
                        </p>
                    </div>
                </div>

                <!-- Accordion 18 -->
                <div class="accordion-item accordion18">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Espace documentaire</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Onglet supplémentaire mettant en avant de fichier .PDF, Word et PowerPoint uploadés par l'organisateur ou les participants. 
                        </p>
                    </div>
                </div>

                <!-- Accordion 19 -->
                <div class="accordion-item accordion19">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Vidéothèque</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Onglet supplémentaire mettant en avant des vidéos partagées par l'organisateur ou les participants. 
                        </p>
                    </div>
                </div>

                <!-- Accordion 20 -->
                <div class="accordion-item accordion20">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>News</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Onglet supplémentaire mettant en avant les news publiées par l'organisateur.
                        </p>
                    </div>
                </div>

                <!-- Accordion 21 -->
                <div class="accordion-item accordion21">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Petites annonces</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Onglet supplémentaire mettant en avant des petites annonces partagées par l'organisateur ou les participants.
                        </p>
                    </div>
                </div>

                <!-- Accordion 22 -->
                <div class="accordion-item accordion22">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Carrousel</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Remplacement du hall d'exposition 3D par un carrousel permettant de référencer une infinité d'exposants.
                        </p>
                    </div>
                </div>

                <!-- Accordion 23 -->
                <div class="accordion-item accordion23">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Foire</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Mise en avant des produits et services de vos exposants avec prix & promotions.
                        </p>
                    </div>
                </div>

                <!-- Accordion 24 -->
                <div class="accordion-item accordion24">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Rendez-vous</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Partage de créneaux de rendez-vous pour chaque représentant présent sur les stands et possibilité pour les participants et les visiteurs de réserver ses créneaux pour organiser un rendez-vous.
                        </p>
                    </div>
                </div>

                <!-- Accordion 25 -->
                <div class="accordion-item accordion25">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Micro-site vitrine de présentation</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Conception, création puis mise en ligne d'un micro-site Internet de promotion de l'événement digital (sur la base de textes et d'illustrations fournis par vos soins).
                        </p>
                    </div>
                </div>

                <!-- Accordion 26 -->
                <div class="accordion-item accordion26">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Marketing Digital & Promotion</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Mise en place d'un plan d'action sur mesure de marketing digital dans le but de faire la promotion de votre événement sur les Réseaux Sociaux et sur Internet en général. Ces actions marketing permettent, à moindre coût, de rayonner de manière plus ou moins étendue de manière régionale, nationale ou internationale en fonction des cibles recherchées.
                        </p>
                    </div>
                </div>

                <!-- Accordion 27 -->
                <div class="accordion-item accordion27">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Motion-Design promotionnel pour les exposants</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Déclinaison & personnalisation du Motion-Design promotionnel MY-VIRTUAL-CONVENTION selon les codes couleurs & logo de votre événement virtuel à destination des exposants pour les aider à les convaincre.
                        </p>
                    </div>
                </div>

                <!-- Accordion 28 -->
                <div class="accordion-item accordion28">
                    <div class="accordion-header" @click="open($event)">
                        <div class="accordion-left">
                            <div class="accordion-icon"></div>
                            <h3>Motion-Design promotionnel pour les visiteurs</h3>
                        </div>
                        <div class="accordion-arrow"></div>
                    </div>
                    <div class="accordion-content">
                        <p>
                            Déclinaison & personnalisation du Motion-Design promotionnel MY-VIRTUAL-CONVENTION selon les codes couleurs & logo de votre événement virtuel à destination des visiteurs pour les aider à les convaincre.
                        </p>
                    </div>
                </div>
                
            </div>
        </section>
        <section class="concept">
            <div class="sep sep-top"></div>
            <div class="sep sep-bottom"></div>                        
            <div class="row">
                <h2>Conception & Développements 100% sur-mesure</h2>
            </div>
            <div class="row">
                <p>Sur la base de notre solution prête à l'emploi, nous pouvons aussi réaliser des développements sur mesure pour construire avec vous la plateforme digitale personnalisée et 100% adaptée à vos besoins : expérience utilisateur, rendus 3D, fonctionnalités, etc.</p>
            </div>
        </section>
    </div>
</template>


<script>
export default {
    name : 'Solutions',
    methods: {
        open(e) {
            e.target.closest('.accordion-header').classList.toggle("active");
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner {
        background-image : url('./../assets/bg-solutions.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .title-section .row {
        width: $boxWidth;
        max-width: 96%;  
        justify-content: center; 
        margin: 40px auto;
    }

    .title-section .row h2 {
        max-width: 80%;
        text-transform: uppercase;
    }

    .solution-1 {
        padding:100px 0;
        background:$backgroundGray;
        display: flex;
        flex-direction: column;
        align-items:center;
        position: relative;
    }

    .solution-1 .sep-top {
        background-image: url('./../assets/border-top-left-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .solution-1 .sep-bottom {
        background-image: url('./../assets/border-bottom-right-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .solution-1 .row, .solution-2 .row, .concept .row {
        width: $boxWidth;
        max-width: 96%;  
        display: flex;
        justify-content: center;
    }

    .solution-1 .text-header {
        text-align: center;
        font-size:20px;
        max-width: 80%;
        line-height: 30px;
    }

    .solution-2 {
        padding:20px 0;
        background:#fff;
        display: flex;
        flex-direction: column;
        align-items:center;
        position: relative;
    }

    .concept {
        padding:150px 0;
        background-image: url('./../assets/bg-stands.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items:center;
        position: relative;
    }

    .concept .sep-top {
        background-image: url('./../assets/border-top-left-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .concept .sep-bottom {
        background-image: url('./../assets/border-bottom-right-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .concept h2 {
        color:#fff;
        font-weight:400;
    }

    .concept p {
        color:#fff;
        font-size:20px;
        line-height: 30px;
        text-align: center;
    }

    /* ACCRODION */
    .accordion-container {
        display: flex;
        flex-direction: column;
        width: $boxWidth;
        max-width: 96%;
        margin:40px 0;
    }

    .accordion-item {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        margin:5px 0;            
    }

    .accordion-header {
        display: flex;
        padding:5px 20px;
        background:#e6e6e6;
        justify-content: space-between;
        align-items: center;
        border-radius:10px;
    }

    .accordion-header h3 {
        font-weight: 400;
    }

    .accordion-item:nth-child(odd) .accordion-header {
        background: #f7f7f7;
    }

    .accordion-item .accordion-header:hover{
        cursor: pointer;
        background: darken(#f7f7f7, 10);
    }

    .accordion-left {
        display: flex;
        align-items: center;
    }

    .accordion-icon {
        height:40px;
        width:40px;
        min-width: 40px;
        border-radius:5px;
        display:flex;
        justify-content: center;
        margin-right:20px;
    }

    .accordion-icon img {
        width:30px;
        height:30px;
    }

    .accordiont-item h3 {
        font-weight:400;
        font-size:20px;
    }

    .accordion-item p {
        font-size:15px;
        line-height: 20px;
    }

    .accordion-content {
        padding:20px;
        background:#fff;
        display: none;
    }

    .accordion-arrow {
        width:40px;
        height:40px;
        min-width: 40px;
        background-image:url('./../assets/arrow-gray.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px;
        transition:0.5s;
    }
    .active + .accordion-content {
        display: flex;
        flex-direction: column;
    }

    .active.accordion-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .active .accordion-arrow  {
        transform: rotate(180deg);
    }

    .accordion-item .featured {
        font-weight:700;
    }

    .accordion-item ul li ul {
        padding-left:15px;
    }

     .accordion-item ul li {
         font-size:15px;
         line-height: 20px;
     }





    /* ACCORDION COLOR */

    .accordion1 .accordion-icon {
        background: #0042ff;
        background-image:url('./../assets/icon1.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion1 h3 {
        color:  #0042ff;
    }

    .accordion2 .accordion-icon {
        background: #007cfa;
        background-image:url('./../assets/icon2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion2 h3 {
        color:  #007cfa;
    }

    .accordion3 .accordion-icon {
        background: #00b6fa;
        background-image:url('./../assets/icon3.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion3 h3 {
        color:  #00b6fa;
    }

    .accordion4 .accordion-icon {
        background: #00cefa;
        background-image:url('./../assets/icon4.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion4 h3 {
        color:  #00cefa;
    }

    .accordion5 .accordion-icon {
        background: #00d3e0;
        background-image:url('./../assets/icon5.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion5 h3 {
        color:  #00d3e0;
    }

    .accordion6 .accordion-icon {
        background: #06d7ad;
        background-image:url('./../assets/icon6.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion6 h3 {
        color:  #06d7ad;
    }
    

    .accordion7 .accordion-icon {
        background: #02d177;
        background-image:url('./../assets/icon7.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion7 h3 {
        color:  #02d177;
    }

    .accordion8 .accordion-icon {
        background: #01aa30;
        background-image:url('./../assets/icon8.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion8 h3 {
        color:  #01aa30;
    }
    
    .accordion9 .accordion-icon {
        background: #82b800;
        background-image:url('./../assets/icon9.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion9 h3 {
        color:  #82b800;
    }

    .accordion10 .accordion-icon {
        background: #ff8a00;
        background-image:url('./../assets/icon10.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion10 h3 {
        color:  #ff8a00;
    }

    .accordion11 .accordion-icon {
        background: #e85200;
        background-image:url('./../assets/icon11.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion11 h3 {
        color:  #e85200;
    }

    /* Accordion color section 2 */

    .accordion12 .accordion-icon {
        background: #e82600;
        background-image:url('./../assets/icon12.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion12 h3 {
        color:  #e82600;
    }

    .accordion13 .accordion-icon {
        background: #e80037;
        background-image:url('./../assets/icon13.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion13 h3 {
        color:  #e80037;
    }

    .accordion14 .accordion-icon {
        background: #c1024e;
        background-image:url('./../assets/icon14.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion14 h3 {
        color:  #c1024e;
    }

    .accordion15 .accordion-icon {
        background: #c1027b;
        background-image:url('./../assets/icon15.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion15 h3 {
        color:  #c1027b;
    }

    .accordion16 .accordion-icon {
        background: #a402c1;
        background-image:url('./../assets/icon16.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion16 h3 {
        color:  #a402c1;
    }

    .accordion17 .accordion-icon {
        background: #6e02c1;
        background-image:url('./../assets/icon17.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion17 h3 {
        color:  #6e02c1;
    }

    .accordion18 .accordion-icon {
        background: #3802c1;
        background-image:url('./../assets/icon18.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion18 h3 {
        color:  #3802c1;
    }

    .accordion19 .accordion-icon {
        background: #0238c1;
        background-image:url('./../assets/icon19.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion19 h3 {
        color:  #0238c1;
    }

    .accordion20 .accordion-icon {
        background: #0042ff;
        background-image:url('./../assets/icon20.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion20 h3 {
        color:  #0042ff;
    }

    .accordion21 .accordion-icon {
        background: #007cfa;
        background-image:url('./../assets/icon21.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion21 h3 {
        color:  #007cfa;
    }

    .accordion22 .accordion-icon {
        background: #00b6fa;
        background-image:url('./../assets/icon22.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion22 h3 {
        color:  #00b6fa;
    }
    
    .accordion23 .accordion-icon {
        background: #00cefa;
        background-image:url('./../assets/icon23.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion23 h3 {
        color:  #00cefa;
    }

    .accordion24 .accordion-icon {
        background: #00d3e0;
        background-image:url('./../assets/icon24.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion24 h3 {
        color:  #00d3e0;
    }

    .accordion25 .accordion-icon {
        background: #06d7ad;
        background-image:url('./../assets/icon25.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion25 h3 {
        color:  #06d7ad;
    }

    .accordion26 .accordion-icon {
        background: #11d37f;
        background-image:url('./../assets/icon26.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion26 h3 {
        color:  #11d37f;
    }

    .accordion27 .accordion-icon {
        background: #01aa30;
        background-image:url('./../assets/icon27.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion27 h3 {
        color:  #11d37f;
    }

    .accordion28 .accordion-icon {
        background: #82b800;
        background-image:url('./../assets/icon28.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }

    .accordion28 h3 {
        color:  #82b800;
    }

    @media screen and (max-width: 768px) {
        .solution-1 .text-header {
            font-size: 15px;
        }

        .accordion-content .col33 {
            width: 98%;
        }

        .accordion-content .row {
            flex-direction: column;
        }
    }
    

    
</style>